import React from 'react';
import Layout from '../components/Layout';
import { Link, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { FaChevronDown } from 'react-icons/fa';
import { parseNameAndSurname } from '../utils/utils';

const Instructor = ({ data }) => {
  const { name, surname, background, about, description, image, profession, awards, exhibitions, otherAchievements } = data.markdownRemark.frontmatter;
  const [showDropdowns, setShowDropdowns] = React.useState([false, false]);
  const cunductedClasses = data.allMarkdownRemark.edges.filter((edge) => parseNameAndSurname(edge.node.frontmatter.instructor[0]) === `${name} ${surname}`);

  const toggleDropdown = (index) => {
    const newShowDropdowns = [...showDropdowns];
    newShowDropdowns[index] = !newShowDropdowns[index];
    setShowDropdowns(newShowDropdowns);

  }


  return (
    <Layout>
      <div className='section-container '>
        <section className='section'>
          <h1>{`${name} ${surname}`}</h1>
        </section>
      </div>
      <div className='section-container section-instructor-wrapper'>
        <div className="instructor-background-container">
          <GatsbyImage image={background.childImageSharp.gatsbyImageData} alt="Instructor background" />
          <div className="instructor-background" >
            <div className="circle-image">
              <GatsbyImage image={image.childImageSharp.gatsbyImageData} alt="Instructor image" />
            </div>
          </div>
        </div>
        <div className='instructor-info-hero' style={{
          display: "flex",
          width: "50%",
          flexDirection: "column",
        }}>
          <h3 className='primary-color' style={{
            marginBottom: "0"
          }}>{`${name} ${surname}`}</h3>
          <p className='lead'>{profession}</p>
        </div>
        <hr />
      </div>
      <div className='section-container'>
        <section className='section'>
          <h3 className='primary-color'>O MNIE</h3>
          <p className='bold-paragraph'>{about}</p>
          <p>{description}</p>
        </section>
        <hr />
      </div>
      {awards?.length > 0 || exhibitions?.length > 0 || otherAchievements?.length > 0 ? (
        <div className="section-container section-alternative">
          <section className="section">
            <div className="prices-info" style={{
              padding: "0 0 2rem 0"
            }}>
              <h5 className="h5-black" style={{
                fontSize: "2rem"
              }}>OSIĄGNIĘCIA</h5>
              <div className="price-info-list">
                {
                  exhibitions?.length > 0 && (
                    <div className={`price-info-item ${showDropdowns[0] ? "active" : ""}`} >
                      <div className="price-info-item-header" onClick={() => toggleDropdown(0)}>
                        <p>Wystawy</p>
                        <FaChevronDown className="chevron-down" />

                      </div>
                      <ul className="price-info-item-content">
                        {exhibitions.map((exhibition, index) => (
                          <li key={index}>
                            {exhibition.name}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )

                }
                {
                  awards?.length > 0 && (
                    <div className={`price-info-item ${showDropdowns[1] ? "active" : ""}`} >
                      <div className="price-info-item-header" onClick={() => toggleDropdown(1)}>
                        <p>Nagrody</p>
                        <FaChevronDown className="chevron-down" />

                      </div>
                      <ul className="price-info-item-content">
                        {awards.map((award, index) => (
                          <li key={index}>
                            {award.name}
                          </li>
                        ))}
                      </ul>
                    </div>

                  )
                }
                {
                  otherAchievements?.length > 0 && (
                    otherAchievements.map((achievement, index) => (
                      <div className={`price-info-item ${showDropdowns[index + 2] ? "active" : ""}`} >
                        <div className="price-info-item-header" onClick={() => toggleDropdown(index + 2)}>
                          <p>{achievement.name}</p>
                          <FaChevronDown className="chevron-down" />
                        </div>
                        <ul className="price-info-item-content">
                          {achievement.achievement.map((ach, index) => (
                            <li key={index}>
                              {ach.name}
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))
                  )
                }
              </div>
            </div>
          </section>
        </div>
      ) : null}

      <div className="section-container">
        {cunductedClasses.length ? (
          <section className="section">
            <h5 className="h5-black" style={{
              fontSize: "2rem"
            }}>PROWADZONE ZAJĘCIA</h5>
            <div>
              {
                cunductedClasses.map((edge, index) => {
                  const { image, description, title, instructor } = edge.node.frontmatter;
                  return (
                    <Link to={edge.node.fields.slug}>
                      <div key={index} className="class-wrapper">
                        <div className="class-image">
                          <GatsbyImage image={image.childImageSharp.gatsbyImageData} alt={title} />
                        </div>
                        <div className="class-info" style={{
                          flexGrow: "3"
                        }}>
                          <h5 className="h5-black" style={{
                            marginTop: "0"
                          }}>{title}</h5>
                          <p>{description.length > 75 ? description.substring(0, 75) + " [...]" : description}</p>
                          <p className='lead' style={{
                            fontSize: "1.2rem",
                            marginBottom: "0"
                          }}>{`Zajęcia prowadzi ${parseNameAndSurname(instructor[0])}`}</p>
                        </div>
                      </div>
                    </Link>
                  )
                })
              }
            </div>
          </section>) : null
        }
      </div>
    </Layout>
  )
}

export default Instructor;

export const pageQuery = graphql`
        query Instructor($id: String!) {
          markdownRemark(id: {eq: $id }) {
          frontmatter {
          name
          surname
          otherAchievements {
            name
            achievement {
              name
            }
          }
          awards {
            name
          }
          exhibitions {
            name
          }
        background {
          childImageSharp {
          gatsbyImageData(quality: 100, height: 400,  placeholder: BLURRED)
            }
          }
        about
        profession
        description
        image {
          childImageSharp {
          gatsbyImageData(quality: 100, width:168, height:168, placeholder: BLURRED)
        }
      }
    }
  }
        allMarkdownRemark(
        filter: {frontmatter: {templateKey: {eq: "class"}}}
        ) {
          edges {
          node {
          id
          fields {
          slug
          }
        frontmatter {
          title
          instructor
        description
        image {
          childImageSharp {
          gatsbyImageData(quality: 100, placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
}
        `

